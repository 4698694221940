<template>
  <div>
    <section class="header-iletisim">
      <h3>{{lang.bizeulasin}}</h3>
      <h2>{{lang.iletisim}}</h2>
    </section>
    <section class="iletisim-icerik">
      <div class="kartlar">
        <div v-for="item in contact.slice().reverse()" class="kart"
             data-aos="fade-up"
             data-aos-offset="0"
             data-aos-delay="0"
             data-aos-duration="700"
             data-aos-easing="ease-in"
             data-aos-mirror="true"
             data-aos-once="false"
             data-aos-anchor-placement="top-bottom"
        >
          <div class="beyaz">
            <h2>{{ item.heading }}</h2>
            <hr>
            <a href=""><i class="fa fa-home"></i> {{item.addr}} </a>
            <a href=""><i class="fa fa-phone"></i> {{ item.phone }}</a>
            <a href=""><i class="fa fa-envelope"></i> {{item.mail}}</a>
          </div>
          <div class="butonlar">
            <a :href="item.directions" target="_blank" class="btn redWhite"><span><i class="fa fa-map-marker"></i> {{lang.yoltarifial}}</span></a>
            <a :href="'tel:+9'+item.phone" target="_blank" class="btn whiteRed v2"><span><i class="fa fa-phone"></i> {{lang.ara}}</span></a>
          </div>
        </div>
      </div>
      <img class="bgcizgi" src="../../../public/assets/img/iletisim-kirmizi-cizgi.png"/>
      <img class="bgcizgi2" src="../../../public/assets/img/sayfa-cizgi-yesil-1.png"/>
    </section>
  </div>
</template>
<script>
import iso from "../../axios";
export default {
  data(){
    return{
      contact:[],
      lang:[],
    }
  },
  created() {
    iso.get('contact')
        .then(response => {
          this.contact = response.data
        })
        .catch(error => {
          console.log(error);
        })
        iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.header-iletisim{
  position: relative;
  clear: both;
  width: 80%;
  margin-left:10%;
  margin-right:10%;
  text-align: left;
}
.header-iletisim h3{
  font-family: bukis;
  color:#af1116;
  font-size:30px;
  margin:8px 0px 8px 0px;
}
.header-iletisim h2{
  font-family: elyazi;
  font-size: 60px;
  margin:8px 0px 8px 0px;
  color:#af1116;
}
.iletisim-icerik{
  position: relative;
  clear: both;
  width: 100%;
}
.iletisim-icerik .bgcizgi{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  z-index: -2;
}
.iletisim-icerik .bgcizgi2{
  position: absolute;
  top: 400px;
  right: 0;
  width: 100%;
  z-index: -3;
}
.iletisim-icerik .kartlar{
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-top: 100px;
}
.iletisim-icerik .kartlar .kart{
  width: 100%;
  max-width: 400px;
  min-width-width: 350px;
}
.iletisim-icerik .kartlar .kart .beyaz{
  padding:4%;
  border-radius: 30px;
  background-color: #f5ebeb;
  color:#111111;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.iletisim-icerik .kartlar .kart .beyaz h2{
  font-size: 22px;
  font-weight: bolder;
  color:#690c10;
}
.iletisim-icerik .kartlar .kart .beyaz hr{
  border-radius: 30px;
  width:100%;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #af1116;
}
.iletisim-icerik .kartlar .kart .beyaz a{
  text-decoration: none;
  margin-top: 10px;
  color: #111111;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  grid-gap: 10px;
}
.iletisim-icerik .kartlar .kart .beyaz a i{
  background: #690c10;
  color:white;
  flex: 100%;
  max-width: 25px!important;
  width: 25px!important;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  vertical-align: middle;
  padding: 10px;
}
.iletisim-icerik .kartlar .kart .butonlar{
  margin-top: 2vw;
  margin-bottom: 2vw;
  width: 100%;
  text-align: center;
}
</style>